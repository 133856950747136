<template>
  <div class="user">
      <div class="line_two">
        <div class="buttonArea">
          <div class="add" @click="goAdd()">
            <i class="el-icon-plus"></i>
            新增角色
          </div>
        </div>
        <el-table :data="dataUserList" style="width: 97%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column type="index" label="序号" sortable width="120" align="center"/>
         <el-table-column label="中文名称" prop="roleName" align="center" width="250"/>  
         <el-table-column label="英文名称" prop="enRoleName" align="center" width="250"/>  
        <el-table-column label="备注" align="center" width="450px">
          <template v-slot="{ row }">
            <div class="textFlow1">
              <textFlow :info="row.description" :lineClamp='2' :hiddenBtn="true" ></textFlow>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="caoZuo">
              <div class="colorOne" style="margin-right:20px" @click="getMenuList(row)">分配权限</div>
              <div class="colorOne" style="margin-right:20px" @click="goEdit(row)">编辑</div>
              <div class="colorOne red" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <!-- 添加角色或修改 -->
      <el-dialog
        :visible.sync="dialogVisible.menu"
        width="40%"
        @close="handleCloseMenu"
        center
        :close-on-click-modal="false"
      >
        <div class="tip">
          <div class="tipOne"></div>
          {{dialogType ? '编辑角色' : '新增角色'}}
        </div>
        <el-form ref="menuObj" :model="menuObj" :rules="menuRules" label-width="110px" :inline="true">   
          <el-form-item label="中文名字:" prop="roleName">
           <el-input v-model="menuObj.roleName" placeholder="请输入中文名字" class="inputItem"/>
          </el-form-item>
          <el-form-item label="英文名字:" prop="enRoleName">
            <el-input v-model="menuObj.enRoleName" placeholder="请输入英文名字" class="inputItem"/>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="menuObj.description" placeholder="请输入备注" class="inputItem" type="textarea" :autosize="{ minRows: 4, maxRows: 6}"/>
          </el-form-item> 
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="editRole" v-if="dialogType">修改</el-button>
          <el-button type="primary" @click="addRole" v-else>确定</el-button>
          <el-button @click="dialogVisible.menu = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 权限弹窗 -->
      <el-dialog
        :visible.sync="dialogVisible.Permission"
        width="40%"
        @close="handleCloseMenu"
        center
        :close-on-click-modal="false"
      >
        <div class="tip">
          <div class="tipOne"></div>
          基本信息
        </div>
        <el-tree
          :data="menuData"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :default-checked-keys="menuExpandedKeys"
          :default-expanded-keys="menuExpandedKeys"
          :props="defaultProps">
        </el-tree>
        <div slot="footer">
          <el-button type="primary" @click="savePower">保存</el-button>
          <el-button @click="dialogVisible.Permission = false">取消</el-button>
        </div>
      </el-dialog>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import { getRoleList, deleteRole, addRole, editRole, getUserRoleAuth } from '@/api/role.js'
import { getAllMenu } from '@/api/menu.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  roleName:''
})
export default {
  name: 'User',
  mixins: [pagination],
  components: { textFlow },
  data() {
    return {
      dataUserList: [],
      detailList: [],
      dialogVisible:{
        menu:false,
        Permission:false
      },
      menuObj:{
        authIdList:[]
      },
      powerList:{
      },
      dialogType:false,
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo },
      menuRules:{
        roleName:[{ required: true, message: '请输入信息名称', trigger: 'blur' }],
        // messageImage:[{ required: true, message: '请输入中文名字', trigger: 'blur' }],
      },
      menuData: [],
      menuExpandedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'authName'
      }
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 弹窗关闭
    handleCloseMenu(){
      this.dialogVisible.menu = false
      this.menuObj = {
        authIdList:[]
      }
    },
    // 新增
    goAdd(){
      this.dialogType = false
      this.dialogVisible.menu = true
    },
    // 编辑
    goEdit(row){
      // console.log(row,"row1");
      this.dialogType = true
      this.dialogVisible.menu = true
      this.menuObj={
          description: row.description,
          id: row.id,
          roleName: row.roleName,
          enRoleName: row.enRoleName
      }
    },
    async editRole(){
      delete this.menuObj.authIdList
      const { data: res } = await editRole(this.menuObj)
      if (res.resultCode === 200) {
        this.$message.success('修改成功')
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }
      this.handleCloseMenu()
    },
    async addRole(){
      const { data: res } = await addRole(this.menuObj)
      if (res.resultCode === 200) {
        this.$message.success('添加成功')   
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }
      this.handleCloseMenu()
    },
    // 查阅权限
    async getMenuList(row){
      this.getRolePermission(row.id)
      this.powerList = row
      this.dialogVisible.Permission = true
      const { data: res } = await getAllMenu()
      if (res.resultCode === 200) {
        this.menuData = res.data
      }
    },
    
    //权限弹窗
    async savePower(){
      let parentList = this.$refs.tree.getCheckedNodes()
      this.powerList.authIdList = this.$refs.tree.getCheckedKeys()
      parentList.map(item=>{
        if(item.parentId != 0) this.powerList.authIdList.push(item.parentId)
      })
      this.powerList.authIdList = [...new Set(this.powerList.authIdList)]
      const { data: res } = await editRole(this.powerList)
      if (res.resultCode === 200) {
        this.$message.success('权限修改成功')
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }
      this.dialogVisible.Permission = false
      this.powerList = {}
    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getRoleList(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    async deleteOne(id){
      this.$confirm(`确认删除所选管理员吗 ?`, {
          type: 'warning'
      }).then(async() => {
        const { data: res } = await deleteRole({id:id})
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
        })
        .catch(() => {})
    },
    async getRolePermission(id) {
      this.menuExpandedKeys = []
      const { data: res } = await getUserRoleAuth({id:id})
      if (res.resultCode === 200) {
        this.$refs.tree.setCheckedKeys([]);
        res.data.auths.map(item=>{
          this.menuExpandedKeys.push(item.id)
        })
        // console.log(res,"1231");
      }
    },
  }
}
</script>

<style lang="less" scoped>
.textFlow1{
  display: flex;
  justify-content: center;
}
.inputItem{
  width: 350px;
}
.tip {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    display: flex;
    margin: -25px 0px 20px 25px;
    .tipOne {
      margin: 3px 6px 0px 0px;
      background: #7c71e1;
      border-radius: 11px;
      width: 4px;
      height: 18px;
    }
  }
.colorOne{
  color: #7C71E1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 120px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
    }
  }
  .caoZuo{
    display: flex;
    justify-content: center;
    .colorOne{
      font-size: 12px;
      color: #7C71E1;
      cursor: pointer;
    }
    .red{
      color: #FF2828;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}

</style>
